
export enum AssetEuroNorm {
  Euro0= 'Euro0',
  Euro1= 'Euro1',
  Euro2= 'Euro2',
  Euro3= 'Euro3',
  Euro4= 'Euro4',
  Euro5= 'Euro5',
  Euro5Eev= 'Euro5Eev',
  Euro6= 'Euro6',
  Euro6a= 'Euro6a',
  Euro6c= 'Euro6c',
  Euro6d= 'Euro6d',
  Euro6dTemp= 'Euro6dTemp',

}