
export enum AssetFuel {
  Gasoline= 'Gasoline',
  Diesel= 'Diesel',
  CNG= 'CNG',
  GPL= 'GPL',
  Hybrid= 'Hybrid',
  Hydrogen= 'Hydrogen',
  Electric= 'Electric',

}